.fa {

	&.fa-xs {
		font-size:50%;
	}
	&.fa-sm {
		font-size:75%;
	}
	&.fa-std {
		font-size:100%;
	}
	&.fa-md {
		font-size:125%;
	}
	&.fa-lg {
		font-size:150%;
	}
	&.fa-xl {
		font-size:165%;
	}

	&.fa-phone {
		@include transform(rotate(30deg));
		vertical-align:middle;
	}

}