.form-control {

	font-size: $std-size;
	color:$grey;
	@media (max-width: $screen-xs-max)
	{
		font-size: $std-xs-size;
	}
	@media (max-width: $screen-sm-max)
	{
		font-size: $std-sm-size;
	}
	@include border-radius(13px);
	height:44px;
	padding:11px 12px;
	@include box-shadow(none);
	border: thin solid $input-border-colour;
	&:focus {
		border: thin solid $input-border-colour;
		@include box-shadow(none);
	}
	&[readonly] {
		background:transparent;
		color:$grey;
	}
	&.form-control-2 {
		max-width:80px;
	}

}

textarea {

	resize: none;
	&.form-control {

		min-height:134px;


	}

}