.relative-container {
	position:relative;
}

.absolute-container {

	position:absolute;
	width:100%;
	top:0;
	left:0;
	right:0;

}