.form-suffix {
	p {

		height:44px;
		line-height:44px;
		margin:0;

	}

}
