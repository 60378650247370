.btn-label {

	margin:0 15px 0 0;
	display:inline-block;
	p {
		margin:0;
		display:inline-block;
	}
}


.btn {

	&.btn-block-xs {
	    @media (max-width: $screen-xs-max)
	    {
	    	display:block;
	    	width:100%;
	    }
	}

	&.btn-tooltip {
		white-space:normal;
		display:inline;
		outline: none;
		padding: 0px; 
		border: 0px; 
		box-sizing: none; 
		background-color: transparent;
		font-size:inherit;
		text-align:center;
		width:24px;
		height:24px;
		line-height:24px;
		@include border-radius(12px);
		background:$blue;
		outline: none !important;
	}


	&.btn-text-link {
		white-space:normal;
		display:inline;
		outline: none;
		padding: 0px; 
		border: 0px; 
		box-sizing: none; 
		background-color: transparent;
		font-size:inherit;
		text-align:left;
		outline: none !important;
	}

	&.btn-gdpr {
		white-space:normal;
		text-align:left;
		display:inline-block;
		outline: none;
		padding: 0 0 0 60px; 
		max-width:238px;
		border: 0px; 
		box-sizing: none; 
		background-color: transparent;
		color:$blue;
		text-decoration:underline;
		position:relative;
		outline: none !important;
		span {
			position:absolute;
			top:0;
			left:20px;
		}
	}

	&.btn-disabled {

	    border-radius: 42px;
	    padding: 8px 40px;
	    line-height:26px;
	    vertical-align: middle;
	    font-family: $font-serif;
	    font-weight: 600;
	    font-size: $lead-size;
	    //box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
	    background:$white;
	    color:$disabled;
	    border:0;
	    outline:0; 
	    position: relative;
	    pointer-events: none;
	    cursor:default;
	    border:thin solid $border;
	    box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
		outline: none !important;

		&:focus, &:active, &:hover {
		    background:$white;
		    color:$disabled;
		    opacity:1;
		}

	}

	&.btn-secondary {

	    border-radius: 42px;
	    padding: 8px 40px;
	    line-height:26px;
	    vertical-align: middle;
	    font-family: $font-serif;
	    font-weight: 600;
	    font-size: $lead-size;
	    box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
	    background:$white;
	    color:$blue;
	    border:0;
	    outline:0; 
	    position: relative;
		outline: none !important;

		&:focus, &:active, &:hover {
			background: $blue-light;
		    color:$white;
		    opacity:1;
		}

	}

	&.btn-primary {

		&.btn-next {

			&:after {
				content:'\F105';
				font-family:FontAwesome;
				color:$white;
				position:absolute;
				right:15px;
				top:8px;
			}

		}


	    border-radius: 42px;
	    padding: 8px 40px;
	    line-height:26px;
	    vertical-align: middle;
	    font-family: $font-serif;
	    font-weight: 600;
	    font-size: $lead-size;
	    //box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
	    background:$blue-light;
	    color:$white;
	    border:0;
	    outline:0; 
	    position: relative;
		outline: none !important;

		&:disabled {
			opacity:0.2 !important;
			&:hover {
				opacity:0.2 !important;
			    background:$blue-light !important;
			    color:$white !important;
			}
		}

		&:focus, &:active, &:hover {
			background: $blue;
		    color:$white;
		    opacity:1;
		}

	}

	&.btn-cta {

	    border-radius: 42px;
	    padding: 8px 15px 8px 75px;
	    line-height:26px;
	    border:thin solid $border;
	    vertical-align: middle;
	    font-family: $font-serif;
	    font-weight: $weight-light;
	    font-size: $std-size;
	    text-align: left;
	    position: relative;
	    box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
	    background:$white;
	    color:$blue;
		outline: none !important;

	    span {
	    	position:absolute;
	    	top:5px;
	    	left:15px;
	    	padding:0 15px 0 0;
	    	width:45px;
	    	height:32px;
	    	border-right:thin solid $border;
	    }

		&:focus, &:active, &:hover {
		}

	}

}

