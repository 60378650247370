.text-decoration {

	&.text-decoration-underline {

		text-decoration:underline;

	}

	&.text-decoration-clickable {

		cursor:pointer !important;

	}
}
