.text-weight {

    &.text-weight-light {

        font-weight:$weight-light;

    }

	&.text-weight-normal {

		font-weight:$weight-standard;

	}

	&.text-weight-bold {

		font-weight:$weight-bold;

	}

}