.display {
	&.display-inline-block {
		display:inline-block;
	}
}

.pull-left {
  float:left;
}


