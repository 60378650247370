.pdf {

    .pdf-number {
        font-family:$font-sans-serif-fallback;
        strong {
            font-weight:500;
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, li, label, blockquote {
        color: $dark !important;
        strong {
            color: $dark !important;

        }
    }

    ul {
        padding-left:25px;
        margin-bottom:15px;
        li {
            line-height: $line-height-sm;
            font-size: $std-size-sm !important;

        }
    }

    .container-fluid {
        padding:0 15px;
        margin:0;
    }

	.page {
		//width: 555px;
		//height: 822px;
		//page-break-inside: avoid !important;
        page-break-after: always !important;
        position: relative;
	}

    .pdf-page-content {
        min-height: 1123px;
        //width:778px;
        //width:800px;
    }

    .page-break-after {
        page-break-after: always !important;
    }

    .no-page-break {
        page-break-inside: avoid !important;
    }

    img {
        display:inline-block;
    }

    .btn {

        &.btn-gdpr {
            color:$blue !important;
            max-width:none;
            white-space:nowrap;
            padding-left:40px;
            span {
                left:0px;
            }
        }


    }


    .row {

        &.row-planner-result, &.row-access-result {

            [class*="col-"] {

                .planner-result-block, .access-result-block {
                    padding-top:0;
                    border-bottom:0;
                    .access-result-block-body-pdf {
                        min-height:70px;
                    }

                }
                border-right:1px dashed $green;

                &:last-child {
                    border-right:none;
                    .planner-result-block, .access-result-block {
                        border-bottom:0;
                    }
                }
            }

        }

    }

    .access-result-heading {
        min-height:75px;
    }
    .access-result-block-outer {
        height:410px;

    }
    .access-result-block-inner {
        min-height:235px;

    }
    .access-result-features {
        min-height:100px;
    }

    .planner-result-block-inner {
        min-height:180px;
    }

    .result {

        &.result-option-1, &.result-option-2 {

            margin:0;
            border-bottom:0; 
            border-right:1px solid $border;
            .panel-body {
                margin:0;
            }

        }

        &.result-option-3 {
            margin:0;
            border-bottom:0; 
            border-right:1px solid $border;
            .panel-body {
                margin:0;
            }
        }

        &.result-option-4 {

            margin:0;
            @include border-radius(0 13px 13px 0);
            position:relative;
            margin-right:-5px;
            //@include border-radius(0);

        }

    }

    .pdf-footer { 
        margin:0 15px;
        p {
            line-height: $line-height-sm;
            font-size: $xxs-xs-size !important;

        }

    }



}

@media print {
  a[href]:after {
    content: none;
  }
}


