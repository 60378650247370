.text-colour {

	&.text-colour-grey {

		color:$grey !important;
		a {
		  color: $grey;

		  &:hover {
		    color: $grey;
		  }

		}

	}

	&.text-colour-white {

		color:$white !important;
		a {
		  color: $white;

		  &:hover {
		    color: $white;
		  }

		}

	}

	&.text-colour-dark {

		color:$dark !important;
		a {
		  color: $dark;

		  &:hover {
		    color: $dark;
		  }

		}

	}

	&.text-colour-blue {

		color:$blue !important;
		a {
		  color: $blue;

		  &:hover {
		    color: $blue;
		  }

		}

	}

	&.text-colour-green {

		color:$green !important;
		a {
		  color: $green;

		  &:hover {
		    color: $green;
		  }

		}
	}

	&.text-colour-red {

		color:$red !important;
		a {
		  color: $red;

		  &:hover {
		    color: $red;
		  }

		}
	}

}