/**
* Set up site paddings
*/
$padding-xxs: 5px;
$padding-xs: 10px;
$padding-std: 15px;
$padding-sm: 20px;
$padding-md: 40px;
$padding-xmd: 60px;
$padding-lg: 80px;
$padding-negative-sm: -20px;
$padding-negative-md: -40px;
$padding-negative-lg: -80px;
$padding-xs-xxs: 5px;
$padding-xs-xs: 10px;
$padding-xs-std: 15px;
$padding-xs-sm: 20px;
$padding-xs-md: 40px;
$padding-xs-xmd: 40px;
$padding-xs-lg: 80px;
$padding-xs-negative-sm: -20px;
$padding-xs-negative-md: -40px;
$padding-xs-negative-lg: -80px;