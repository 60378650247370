$h1-size : 38px;
$result-size-lg : 38px;
$result-size-md : 32px;
$h2-size : 36px; 
$h3-size : 28px; 
$h4-size : 23px;
$h5-size : 18px;
$h6-size : 14px;
$lead-size : 18px;
$std-size : 16px;
$std-size-sm : 15px;
$std-size-xs : 14px;
$s-size : 17px;
$xs-size : 13px;
$xxs-size : 12px;
$xxxs-size : 11px;


//SM options
$h1-sm-size : 38px;
$result-sm-size-lg : 38px;
$result-sm-size-md : 32px;
$h2-sm-size : 36px; 
$h3-sm-size : 28px; 
$h4-sm-size : 23px;
$h5-sm-size : 18px;
$h6-sm-size : 14px;
$lead-sm-size : 18px;
$std-sm-size : 16px;
$s-sm-size : 17px;
$xs-sm-size : 13px;
$xxs-sm-size : 12px;
$xxxs-sm-size : 11px;


//XS options
$h1-xs-size : 38px;
$result-xs-size-lg : 38px;
$result-xs-size-md : 32px;
$h2-xs-size : 36px; 
$h3-xs-size : 28px; 
$h4-xs-size : 23px;
$h5-xs-size : 18px;
$h6-xs-size : 14px;
$lead-xs-size : 18px;
$std-xs-size : 16px;
$s-xs-size : 17px;
$xs-xs-size : 13px;
$xxs-xs-size : 12px;
$xxxs-xs-size : 11px;
