
.tooltip-inner {
    background-color: $contrast;
    color: $dark;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,.08);
    @include border-radius(12px);
}

.tooltip {

}

.tooltip-arrow {

    //box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);

}

.tooltip.top .tooltip-arrow {
    border-top-color: $contrast;
}

.tooltip.right .tooltip-arrow {
    border-right-color: $contrast;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $contrast;
}

.tooltip.left .tooltip-arrow {
    border-left-color: $contrast;
}