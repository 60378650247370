.checkbox-btn {
	display:inline-block;
	margin:0 10px 0 0;
	position:relative;
	&:last-child {
		margin-right:0;
	}
	input[type=checkbox] {
		opacity:0;
		position:absolute;
		right:0;
	}
	input[type=checkbox] + label {
	    padding: 8px 30px 8px 95px;
	    line-height:26px;
	    min-width:175px;
		margin:0;
	    border:thin solid $border;
	    vertical-align: middle;
		display:inline-block;
	    font-weight: $weight-light;
	    font-size: $std-size;
	    text-align: left;
	    position: relative;
	    box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
	    background:$white;
	    color:$blue;
		@include border-radius(42px);
		cursor:pointer;
		outline:0;
		.check {
	    	width:18px;
	    	height:18px;
	    	display:block;
	    	@include border-radius(4px);
	    	border:thin solid $border;
	    	position:absolute;
	    	top:13px;
	    	left:15px;
	    	&:before {
	    		content:'';
		    	display:block;
		    	width:12px;
		    	height:12px;
		    	@include border-radius(2px);
		    	border:thin solid $border;
		    	position:absolute;
		    	top:2px;
		    	left:2px;
	    	}
		}
		.check-tick {
	    	position:absolute;
	    	z-index:100;
	    	top:12px;
	    	left:19px;
    		content:'';
	    	display:block;
		}
	    .icon {
	    	position:absolute;
	    	top:5px;
	    	left:45px;
	    	padding:0 15px 0 0;
	    	width:45px;
	    	height:30px;
	    	border-left:thin solid $border;
	    	&.icon-optin-sms {
	    		margin-top:3px;
	    	}
	    }
    }
	input[type=checkbox] + label:hover {
		background:$contrast-green;
    }
	input[type=checkbox]:checked + label {
		.check {
			border-color:$green;
		}
		.check-tick {
    		width:16px;
    		height:16px;
    		background:url(../../images/tick-sm-green.svg) no-repeat center center;
    		background-size:16px 16px;
		}
    }
    input[type=checkbox]:disabled + label, input[type=checkbox]:disabled + label:hover {
    }

}

