.result {

	&.result-option-1, &.result-option-2 {

        border-bottom:thin solid $border;
        margin:0 15px;
        .panel-body {
            margin:0 -15px;
            @media (min-width: $screen-md-min) {
                margin:0;
            }
        }
        @media (min-width: $screen-md-min) {
            margin:0;
            border-bottom:0; 
            border-right:thin solid $border;
        }

	}

    &.result-option-3 {
        margin:0 15px;
        border-bottom:thin solid $border;
        .panel-body {
            margin:0 -15px;
            @media (min-width: $screen-md-min) {
                margin:0;
            }
        }
        @media (min-width: $screen-md-min) {
            margin:0;
            border-bottom:0; 
            border-right:thin solid $border;
        }

    }

    &.result-option-4 {

        background:$contrast-green !important;
        margin:15px;
        @include border-radius(13px);
        @media (min-width: $screen-md-min) {
            margin:0;
            @include border-radius(0 13px 13px 0);

        }    
    }
}