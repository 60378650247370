.radio-btn {
	display:inline-block;
	position:relative;
	margin:0 5px 0 0;
	&:last-child {
		margin-right:0;
	}
	input[type=radio] {
		opacity:0;
		position:absolute;
		right:0;
	}
	input[type=radio] + label {
		margin:0;
		height:44px;
		line-height:44px;
		text-align:center;
		display:inline-block;
		font-family: $font-serif;
		font-weight:700;
		width:100px;
		font-size:$lead-size;
		border:thin solid $blue;
		color:$blue;
		background:$white;
		@include border-radius(44px);
		cursor:pointer;
		outline:0;
    }
    input[type=radio]:checked + label {
		color:$white !important;
		background:$green !important;
		border:thin solid $green !important;
    }
	input[type=radio] + label:hover, input[type=radio] + label:focus {
		color:$white;
		background:$blue-light;
    }
    input[type=radio]:disabled + label, input[type=radio]:disabled + label:hover {
		color:$blue;
		background:$white;
		cursor:default;
    }
    &.radio-btn-round {
		width:44px;
    }

}

