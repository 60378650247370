.input-group {

	&.right-aligned {
		.form-control {
			text-align:right;
		}
		.input-group-addon {
			@include border-radius(0 13px 13px 0);
			border:none;
			border-left:thin solid $input-border-colour;

		}
	}
	@include border-radius(13px);
	height:44px;
	@include box-shadow(none);
	border: thin solid $input-border-colour;
	.input-group-addon {
		@include border-radius(13px 0 0 13px);
		background:$contrast;
		border:none;
		border-right:thin solid $input-border-colour;
		font-size: $std-size;
		color:$dark;
		@media (max-width: $screen-xs-max)
		{
			font-size: $std-xs-size;
		}
		@media (max-width: $screen-sm-max)
		{
			font-size: $std-sm-size;
		}
	}
	.form-control {
		border:none;
		&:focus {
			border:none;
		}
	}
	.input-group-addon + .form-control {
		//padding-left:0;
	}
}