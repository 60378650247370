.row {

	.col-separator {

		border-right:thin solid $border;
	}

	&.row-no-padding {
		margin-left:0;
		margin-right:0;
	}

	&.row-no-padding > [class*="col-"], .col-no-padding {
	    padding-left: 0 !important;
	    padding-right: 0 !important;
	}

	&.row-sm-padding {
		margin-left:-10px;
		margin-right:-10px;
	}

	&.row-sm-padding > [class*="col-"], .col-sm-padding {
	    padding-left: 10px !important;
	    padding-right: 10px !important;
	}

	&.row-xs-padding {
		margin-left:-5px;
		margin-right:-5px;
	}

	&.row-xs-padding > [class*="col-"], .col-xs-padding {
	    padding-left: 5px !important;
	    padding-right: 5px !important;
	}

	@media (min-width: $screen-sm-min) {
		
		.col-sm-no-padding, &.row-sm-no-padding > [class*="col-"] {
		    padding-left: 0 !important;
		    padding-right: 0 !important;
		}


	}

	&.row-dashed {

		&.row-dashed-green {

			[class*="col-"] {

				border-right:1px dashed $green;

			}

		}

		[class*="col-"] {

			&:last-child {
				border-right:none;
			}
		}

	}

	&.row-planner-result, &.row-access-result {

		[class*="col-"] {

			.planner-result-block, .access-result-block {
				border-bottom:1px dashed $green;
				padding-top:15px;
				@media (min-width: $screen-md-min) {
					padding-top:0;
					border-bottom:0;
				}

			}

			@media (min-width: $screen-md-min) {
				border-right:1px dashed $green;
			}
			&:last-child {
				@media (min-width: $screen-md-min) {
					border-right:none;
				}
				.planner-result-block, .access-result-block {
					border-bottom:0;
				}
			}
		}

	}


}
