$black: #000000;
$white: #ffffff;
$green: #178830;
$blue-light: #0095f4;
$blue: #04589b;
$red:   #D0021B;
$dark:  #3D3D3D;
$grey: $dark;
$contrast: #f7f7f7;
$contrast-dark: #EDF2F4;
$contrast-green: #EEF7E8;
$hr-colour: #D8D8D8;
$border: #B8B8B8;
$disabled: #B8B8B8;
$input-border-colour: $border;

