

a, input[type=submit], button {
  opacity: 1;
  text-decoration: none;
  @include transition(opacity .25s, color 0.5s, background-color 0.5s, border-color .5s, ease-in-out);

  &:hover,
  &:active,
  &:focus {
    opacity: 0.75;
    text-decoration: none;
  }
}


h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, li, label, blockquote {
  margin: 0 0 0.6em 0;
  padding: 0;
  a {
  }
}


h1, .h1 {
  font-weight: $weight-standard;
  font-family:$font-serif;
  font-size: $h1-size;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h1-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h1-xs-size;
  }
}

h2, .h2 {
  font-weight: $weight-standard;
  font-family:$font-serif;
  font-size: $h2-size;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h2-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h2-xs-size;
  }
}

h3, .h3 {
  font-weight: $weight-standard;
  font-size: $h3-size;
  font-family:$font-serif;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h3-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h3-xs-size;
  }
}

h4, .h4 {
  font-weight: $weight-standard;
  font-size: $h4-size;
  font-family:$font-serif;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h4-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h4-xs-size;
  }
}

h5, .h5 {
  font-weight: $weight-standard;
  font-size: $h5-size;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h5-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h5-xs-size;
  }
}

h6, .h6 {
  font-weight: $weight-standard;
  font-size: $h6-size;
  @media (max-width: $screen-sm-max)
  {
    font-size: $h6-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $h6-xs-size;
  }
}

li {
  font-weight: $weight-light;
  font-size: $std-size;

  @media (max-width: $screen-sm-max)
  {
    font-size: $std-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $std-xs-size;
  }
}

p {
  font-weight: $weight-light;
  font-size: $std-size;
  line-height: $line-height-none;

  @media (max-width: $screen-sm-max)
  {
    font-size: $std-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $std-xs-size;
  }

  &.result-lg, .result-lg {
    font-size:$result-size-lg;
  }

  &.result-md, .result-md {
    font-size:$result-size-md;
  }

  &.lead {
    font-size: $lead-size;
    line-height: $line-height-sm;

    @media (max-width: $screen-sm-max)
    {
      font-size: $lead-sm-size;
    }
    @media (max-width: $screen-xs-max)
    {
      font-size: $lead-xs-size;
    }
  }
  &.info {
    padding:0 0 0 45px;
    background:url(../../images/icon-info-circle-blue.svg) no-repeat left 15px !important;
    line-height: $line-height-sm;
  }
  strong {
    font-weight:500;
  }


  small {
    font-size: $xs-size;

    @media (max-width: $screen-sm-max)
    {
      font-size: $xs-sm-size;
    }
    @media (max-width: $screen-xs-max)
    {
      font-size: $xs-xs-size;
    }

    small {
      font-size: $xxs-size;

      @media (max-width: $screen-sm-max)
      {
        font-size: $xxs-sm-size;
      }
      @media (max-width: $screen-xs-max)
      {
        font-size: $xxs-xs-size;
      }

      small {
        font-size: $xxxs-size;

        @media (max-width: $screen-sm-max)
        {
          font-size: $xxxs-sm-size;
        }
        @media (max-width: $screen-xs-max)
        {
          font-size: $xxxs-xs-size;
        }

      }
    }
  }
}

label {
  display:block;
  font-weight: $weight-standard;
  font-size: $std-size;
  margin:0 0 0.3em 0;

  @media (max-width: $screen-sm-max)
  {
    font-size: $std-sm-size;
  }
  @media (max-width: $screen-xs-max)
  {
    font-size: $std-xs-size;
  }

}

strong, bold {
  font-weight: $weight-bold;
}

blockquote {
}


