.background {

    &.background-contrast {

        background:$contrast !important;

    }

    &.background-contrast-green {


        background:$contrast-green !important;

    }

    &.background-rounded-right {

        @include border-radius(0 13px 13px 0);

    }

    &.background-telephone {

        background:url(../../images/icon-telephone.svg) no-repeat right top !important;
        background-size: 233px 91px;

    }

}