.icon {

    display:inline-block;

    &.icon-info-circle-blue {
        background:url(../../images/icon-info-circle-blue.svg) no-repeat center center !important;
        background-size: 30px 30px;
        width:30px;
        height:30px;
    }

    


    &.icon-tick-green {
        background:url(../../images/icon-tick-green.png) no-repeat center center !important;
        background-size: 15px 12px;
        width:15px;
        height:12px;
    }

    &.icon-cross-red {
        background:url(../../images/icon-cross-red.png) no-repeat center center !important;
        background-size: 15px 12px;
        width:15px;
        height:12px;
    }


    &.icon-live-chat {
        background:url(../../images/icon-live-chat.svg) no-repeat center center !important;
        background-size: 31px 30px;
        width:31px;
        height:30px;
    }

    &.icon-callback {
        background:url(../../images/icon-callback.svg) no-repeat center center !important;
        background-size: 32px 34px;
        width:32px;
        height:34px;
    }

    &.icon-email {
        background:url(../../images/icon-email.svg) no-repeat center center !important;
        background-size: 26px 29px;
        width:26px;
        height:29px;
    }

    &.icon-landing {
        background:url(../../images/icon-landing.svg) no-repeat center top !important;
        //width:302px;
        //height:122px;
        //background-size: 181px 73px;
        width:100%;
        padding-bottom:55%;
    }

    &.icon-gdpr-shield {
        background:url(../../images/icon-gdpr-shield.svg) no-repeat center center !important;
        background-size: 26px 35px;
        width:26px;
        height:35px;

    }

    &.icon-promo-book {
        background:url(../../images/icon-promo-book.svg) no-repeat center center !important;
        background-size: 80px 80px;
        width:80px;
        height:80px;

    }

    &.icon-promo-calc {
        background:url(../../images/icon-promo-calc.svg) no-repeat center center !important;
        background-size: 79px 71px;
        width:79px;
        height:71px;

    }

    &.icon-optin-email {
        background:url(../../images/icon-optin-email.svg) no-repeat center center !important;
        background-size: 30px 30px;
        width:30px;
        height:30px;

    }

    &.icon-optin-post {
        background:url(../../images/icon-optin-post.svg) no-repeat center center !important;
        background-size: 30px 30px;
        width:30px;
        height:30px;

    }

    &.icon-optin-sms {
        background:url(../../images/icon-optin-sms.svg) no-repeat center center !important;
        background-size: 30px 30px;
        width:30px;
        height:30px;

    }

    &.icon-optin-phone {
        background:url(../../images/icon-optin-phone.svg) no-repeat center center !important;
        background-size: 30px 30px;
        width:30px;
        height:30px;

    }

    &.icon-plus-green {
        background:url(../../images/icon-plus-green.svg) no-repeat center center !important;
        background-size: 25px 23px;
        width:25px;
        height:23px;

    }

    &.icon-plus-green-png {
        background:url(../../images/icon-plus-green.png) no-repeat center center !important;
        background-size: 25px 23px;
        width:25px;
        height:23px;

    }

    &.icon-question {
        background:url(../../images/icon-question.svg) no-repeat center center !important;
        background-size: 7px 13px;
        width:7px;
        height:13px;

    }

}