hr {
	height:1px;
	&.thick {
		height:3px;
	}
	border:none;
	margin:5px 0 10px 0;
	background:$hr-colour !important;
	&.white {
		background:$white !important;
	}
}