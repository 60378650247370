.modal-dialog {


	&.modal-lg {
		@media (min-width: $screen-sm-min) {
			width:auto;
			margin:30px;
		}
		@media (min-width: $screen-md-min) {
			width:auto;
			margin:30px;
		}
		@media (min-width: $screen-lg-min) {
			width:900px;
			margin:30px auto;
		}
	}

	&.modal-sm, &.modal-md, &.modal-lg {

		.modal-content {
		    @include border-radius(13px);
			padding:30px;
			.closebtn {
			    position: absolute;
			    right: 0;
			    top: 0;
			    cursor: pointer;
			    z-index: 99;
			    &:before {
			    	content:"";
			    	position:absolute;
			    	top:15px;
			    	right:15px;
			    	width:24px;
			    	height:24px;
			    	background:url(../../images/icon-close.svg) no-repeat left top;
			    	background-size:24px 48px;
			    }
			    &:focus, &:hover {
				    &:before {
				    	background-position: left bottom;
				    }
			    }

			}

		}


	}


}