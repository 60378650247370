.radio-default {
	display:inline-block;
	position:relative;
	margin:0 5px 0 0;
	&:last-child {
		margin-right:0;
	}
	input[type=radio] {
		opacity:0;
		position:absolute;
		right:0;
	}
	input[type=radio] + label {
		font-size:$std-size;
		cursor:pointer;
		outline:0;
		padding:0 0 0 30px;
		&:before {
			content:'';
			@include border-radius(20px);
			width:20px;
			height:20px;
			background:$white;
			border:thin solid $border;
			position:absolute;
			left:0;
			top:4px;
		}
		&:after {
			content:'';
			@include border-radius(15px);
			width:12px;
			height:12px;
			border:thin solid $border;
			background:$white;
			position:absolute;
			left:4px;
			top:8px;
		}
    }
	input[type=radio] + label:hover, input[type=radio] + label:focus {
		&:after {
			background:$blue;
		}
    }
    input[type=radio]:checked + label {
		&:after {
			background:$green;
		}
    }

}

