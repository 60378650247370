html, body {
  height: 100%;
}

html * {
	max-height:1000000px;
}


body {
    //background:$contrast !important;
    background:none transparent;
	padding:0;
	margin:0;
	font-family:$font-sans-serif;
	line-height: $line-height-md;
	font-size: $std-size;
	color: $dark;

}

