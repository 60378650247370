/**
* Set up site margins
*/
$margin-xxxs: 3px;
$margin-xxs: 5px;
$margin-xs: 10px;
$margin-std: 15px;
$margin-sm: 30px;
$margin-md: 40px;
$margin-lg: 100px;
$margin-negative-xxs: -5px;
$margin-negative-xs: -10px;
$margin-negative-std: -15px;
$margin-negative-xsm: -25px;
$margin-negative-sm: -30px;
$margin-negative-md: -40px;
$margin-negative-lg: -100px;
$margin-xs-xxxs: 3px;
$margin-xs-xxs: 5px;
$margin-xs-xs: 10px;
$margin-xs-std: 15px;
$margin-xs-sm: 30px;
$margin-xs-md: 40px;
$margin-xs-lg: 100px;
$margin-xs-negative-xxs: -5px;
$margin-xs-negative-xs: -10px;
$margin-xs-negative-std: -15px;
$margin-xs-negative-xsm: -25px;
$margin-xs-negative-sm: -30px;
$margin-xs-negative-md: -40px;
$margin-xs-negative-lg: -100px;