.text-align {

	&.text-align-center-xs {

		@media (max-width: $screen-xs-max) {
			text-align:center;
		}

	}

    &.text-align-center-sm {

        @media (min-width: $screen-sm-min) {
            text-align:center;
        }

    }

    &.text-align-center-md {

        @media (min-width: $screen-md-min) {
            text-align:center;
        }

    }

	&.text-align-right {
		text-align:right;
	}

    &.text-align-right-sm {
        @media (min-width: $screen-sm-min) {
            text-align:right;
        }
    }

}  
