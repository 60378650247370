.form-group {

	margin-bottom: 25px;

	&.form-group-read-only {

		input[type='text'].form-control {
			opacity:0.4;
			cursor:not-allowed;
			//border-color:$white;
			//color:$grey;
			//background:transparent;
		}

		.input-group {
			opacity:0.4;
			//border-color:$white;
			input[type='text'].form-control {
				opacity:0.4;
				//color:$grey;
				//border-color:transparent;
				//@include box-shadow(none);
				//background:transparent;
			}
			.input-group-addon {
				opacity:0.4;
				//visibility:hidden;
			}
		}


	}

	&.has-error {

		input[type='text'].form-control {
			border-color:$red;
		}

		.input-group {
			border-color:$red;
			input[type='text'].form-control {
				border-color:transparent;
				@include box-shadow(none);
			}
		}

		.radio-btn {
			input[type=radio] + label {
				border-color:$red;
			}
		}	

	}

}