.list {

	padding-left:0;
	margin-left:0;
	margin-bottom:0;
	list-style:none;





}