.panel {

    &.panel-rounded {

        @include border-radius(13px);

    }

    &.panel-rounded-full {
        @include border-radius(50px);
    }


    &.panel-white {

        background:$white !important;

    }

    &.panel-contrast-dark {

        background:$contrast-dark !important;

    }

    &.panel-contrast {

        background:$contrast !important;

    }

    &.panel-shadow {
        box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
    }

    &.panel-bordered {
        border:thin solid $border;
        box-shadow: 0.5px 0.5px 1px 1px rgba(0,0,0,.08);
    }

    .panel-body {

        &.panel-body-top-zero {

            padding-top:0;

        }

        &.panel-body-left-zero {

            padding-left:0;

        }

        &.panel-body-right-zero {

            padding-right:0;

        }

        &.panel-body-bottom-zero {

            padding-bottom:0;

        }

        &.panel-body-md {

            padding:30px;
        }

    }
}