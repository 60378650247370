.para {

    &.para-info-circle-blue {

        padding:0 0 0 45px;
        position:relative;
        &:before {
            position:absolute;
            top:50%;
            margin-top:-15px;
            left:0;
            content:'';
            background:url(../../images/icon-info-circle-blue.svg) no-repeat center center !important;
            background-size: 30px 30px;
            width:30px;
            height:30px;

        }

    }

}