.line-height {
	&.line-height-normal {
		line-height: normal;
	}
	&.line-height-zero {
		line-height: $line-height-none;
	}
	&.line-height-reduce-xs {
		line-height: $line-height-reduce-xs;
	}
	&.line-height-reduce-sm {
		line-height: $line-height-reduce-sm;
	}
	&.line-height-reduce {
		line-height: $line-height-reduce;
	}
	&.line-height-xs {
		line-height: $line-height-xs;
	}
	&.line-height-sm {
		line-height: $line-height-sm;
	}
	&.line-height-md {
		line-height: $line-height-md;
	}
	&.line-height-lg {
		line-height: $line-height-lg;
	}
}